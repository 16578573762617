


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.shopDecorate {
  .img-el-upload {
    width: 80px;
    height: 50px;
    .el-upload {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .el-switch__core {
    width: 45px !important;
  }
}
