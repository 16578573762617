

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.shopDecorate {
  .phone_top {
    padding: 20px 13px 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .setBox {
      border: 1px solid #dcdfe6;
      padding: 5px 8px;
      border-radius: 20px;
    }
  }
  .phone_bot {
    border-top: #000 solid 1px;
    padding: 10px 13px 20px;
  }

  .phonebox {
    .top {
      position: relative;
      z-index: 100;
      cursor: pointer;
    }
    width: 375px;
    min-height: 500px;
    height: 750px;
    border: 1px solid #000;
    border-radius: 40px;
    > .phone_content {
      padding: 0 13px;
      margin-bottom: 10px;
      > div {
        position: relative;
        margin-bottom: 10px;
      }
    }
    .phone_banner {
      min-height: 150px;
      text-align: center;
      background-color: #999;
    }
    .phone_application {
      width: 100%;
      min-height: 115px;
      // height: auto;
      .application_item {
        width: 25%;
        margin-bottom: 5px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }
    }
    .pushBanner {
      width: 100%;
      height: 120px;
    }
    .Selected {
      // min-height: 300px;
      .course_list {
        padding: 10px 0;
        img {
          width: 100px;
          height: 70px;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }
    .workPlace {
      padding: 10px 5px 20px;
      .course_list {
        padding: 10px 0;
        img {
          width: 100px;
          height: 70px;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(203, 209, 255, 0.4);
    z-index: 99;
    width: 100%;
    height: 100%;
    border: 2px dashed #001bff;
    display: flex;
    justify-content: flex-end;
    div {
      align-self: flex-end;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 5px;
      }
    }
  }
}
